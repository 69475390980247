<template>
    <div class="view login h-100">
        <v-container fill-height>
            <v-row justify="center" align="center" class="h-100">
                <v-col xs="12" sm="6" md="5" lg="4" xl="3">  
                        <div class="card card-blue card-login" data-aos="fade">
                            <div class="card-body">
                                <h5 class="card-title">
                                    <img class="logo" src="/svg/logo_small.svg" alt=""> <div class="text">Remote2All Systems</div>
                                </h5>
                                <v-divider /><br>
                                <form @submit="onSubmit">
                                    <div class="fields">
                                        <v-select ref="clientCode" v-model="credentials.clientCode" :rules="[() => !!credentials.clientCode || 'This field is required']" :items="clientCodes" item-value="key" item-text="label" label="Account type" outlined />
                                        <v-text-field ref="username" v-model="credentials.username" :rules="[() => !!credentials.username || 'This field is required']" label="Email" outlined />
                                        <v-text-field ref="password" v-model="credentials.password" type="password" :rules="[() => !!credentials.password || 'This field is required']"  label="Password" outlined />
                                    </div>
                                    <div class="actions">
                                        <v-btn :elevation="0" :disabled="loading" class="ml-auto" color="primary" type="submit">LOGIN</v-btn>
                                    </div>                                
                                    
                                </form>
                            </div>
                        </div>
                    
                </v-col>
            </v-row>
        </v-container>
    </div>
</template>
<style lang="scss">

.toasted {
    font-family: 'Fira Sans';
}


html,
body,
#app {
    height: 100%;
}

.h-100 {
    height: 100%;
}

.w-100 {
    width: 100%;
}


.view.login {

    background-color: #efefef;

    .card-login{

        margin-top: 0;
        margin-bottom: 0px;

        .actions {
                text-align: right; 
        }

        .card-title {

            .logo {
                display: inline-block;
            }

            .text {
                position: relative;
                top: -7px;
                left: 10px;
                font-size: 18px;
                display: inline-block;
                font-weight: 400;
                
            } 

           
        }

    }
}

.pull-right {
    float: right;
}

.btn {
    padding: 10px 30px;
    font-size: 16px;
}
</style>
<script>
import { mapGetters, mapActions } from 'vuex';
export default {

    name: 'Login',
    data: () => ({
        valid: true,
        loading: false,
        clientCodes: [{
                label: 'R2A Admin',
                key: 'R2AWebAdmin'
            },
            {
                label: 'Casino Admin',
                key: 'UnigensWebAdmin'
            },
            {
                label: 'Rhino Admin',
                key: 'RhinoWebAdmin',
            }
        ],
        credentials: {
            clientCode: 'RhinoWebAdmin',
            username: '',
            password: '',
        }

    }),
    computed: mapGetters(['loggedIn']),
    methods: {

        ...mapActions(['login']),

        onSubmit(e) {

            e.preventDefault();

            this.loading = true;
            this.valid = true;
            
            // Validate each fields
            Object.keys(this.credentials).forEach(item => {
                let fieldValid = this.$refs[item].validate(true);
                // Unvalidate form if any fields are not valid 
                if(!fieldValid) {
                    this.valid = false;
                }
            });

            // Exit if not valid
            if(!this.valid) {
                Vue.toasted.error('Please fill in the form');
                this.loading = false;
                return;
            }



            this.login({
                clientCode: this.credentials.clientCode,
                username: this.credentials.username,
                password: this.credentials.password
            }).then(response => {
                self.loading = false;
                if (this.loggedIn === true) {
                    this.$router.push({ path: '/' });
                } else {
                    this.valid = false;
                }
            }).catch(error => {
                this.loading = false;
            });
        },

    },
}
</script>